import * as React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import { RichText } from 'prismic-reactjs'
import { Container, Row, Col } from 'react-bootstrap'

const PageTemplate = (props) => {
  const data = props.data
  const doc = data.prismicBlogPost.data

  return (
    <Layout>
      <Seo title={doc.blog_title + "| Saasternatives: The Saas Blog"} />
      <Container>
        <Row style={{marginTop: "4em"}}>
          <Col md={9}>
            <div className='blog-post'>
              <p className='my-0'>{doc.date}</p>
              <h1 className='mb-4'>
                {doc.blog_title}
              </h1>
              <img alt={'Banner image for' + doc.blog_title} style={{marginBottom: "4em", marginTop:'2em'}} src={doc.banner_image} />

              {RichText.render(doc.content.richText, {})}
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
query PostQuery($id: String) {
  prismicBlogPost(uid: { eq: $id })
   {
    data {
      banner_image
      blog_title
      content {
        richText
      }
      date
      description
    }
  }
}


`
export default PageTemplate
